import { api, omitBlankEntries } from './api'

type RedirectList = {
  id: number
  pdn: string
  mpl: string
  client_type: string
  kpk_status: string
  interval_min: number
  interval_max: number
  company: string
  result_status: string
}

type EditRedirect = {
  id: number
  interval_min: number
  interval_max: number
}

export const RedirectSettingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getListSetting: builder.query<
      RedirectList[],
      { limit: number; offset: number }
    >({
      query: (params) => ({
        url: '/crm/v1/switch/fetch-switch-overview',
        params: omitBlankEntries(params)
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'RedirectSettings', id }) as const),
        { type: 'RedirectSettings' as const, id: 'LIST' }
      ]
    }),
    updateSetting: builder.mutation<void, EditRedirect>({
      query: (body) => ({
        url: '/crm/v1/switch/set-switch-intervals',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['RedirectSettings']
    })
  })
})

export const { useGetListSettingQuery, useUpdateSettingMutation } =
  RedirectSettingsApi
