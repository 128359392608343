import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import { OffsetNotice } from 'src/Components/Common/LimitNotice'

import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import model from 'src/model'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import List from 'src/Components/Common/EntitiesView/List'
import { LIST_LIMIT } from 'src/client'
import { useGetListSettingQuery } from 'src/services/redirectSettings'

export const RedirectSettings = () => {
  const { t } = useTranslation()
  const [offset, setOffset] = useState(0)

  const {
    data: RedirectList,
    error,
    isLoading,
    refetch
  } = useGetListSettingQuery({
    limit: LIST_LIMIT,
    offset: offset
  })

  useEffect(() => {
    refetch()
  }, [refetch, offset])

  const next = () => {
    setOffset((prev) => prev + model.LIST_LIMIT)
  }

  const prev = () => {
    setOffset((prev) => Math.max(prev - model.LIST_LIMIT, 0))
  }

  const title = t('Redirect settings')

  if (error) {
    return <ErrorPage title={title} error={error} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <div
            className='btn-group gap-2 p-3'
            role='group'
            aria-label='Basic example'
          >
            <button
              type='button'
              className='btn btn-primary d-flex align-items-center'
              disabled={offset === 0 || isLoading}
              onClick={prev}
            >
              <i className='ri-arrow-left-line fs-15 me-2' />
              {`${t('Prev')} ${model.LIST_LIMIT}`}
            </button>
            <button
              type='button'
              className='btn btn-primary d-flex align-items-center'
              disabled={
                isLoading ||
                (RedirectList ? RedirectList.length < model.LIST_LIMIT : false)
              }
              onClick={next}
            >
              {`${t('Next')} ${model.LIST_LIMIT}`}
              <i className='ri-arrow-right-line fs-15 ms-2' />
            </button>
          </div>

          <OffsetNotice offset={offset} limit={model.LIST_LIMIT} />
          {!RedirectList ? (
            <LoadingPage />
          ) : (
            <List
              list={RedirectList.map((field) => {
                return {
                  ...field
                }
              })}
              className={undefined}
              style={{ width: 'fit-content' }}
              fieldsDescription={{
                company: {},
                pdn: {},
                mpl: {},
                client_type: { label: 'client type' },
                kpk_status: { label: 'status KPK' },
                interval_min: { label: 'interval min' },
                interval_max: { label: 'interval max' },
                result_status: { label: 'result status' }
              }}
              hover
              actions={RedirectList.map((field) => {
                return (
                  <Link
                    key={field.id}
                    to={`/redirect-settings-edit?id=${field.id}`}
                    className='link-success fs-15'
                  >
                    <i className='ri-edit-2-line'></i>
                  </Link>
                )
              })}
            />
          )}
        </CardBody>
      </Card>
    </div>
  )
}
