import {
  CombineApplication,
  useGetApplicationDocumentsQuery,
  useGetApplicationLoanQuery,
  useGetApplicationSignedDocumentsQuery,
  useGetSingleApplicationQuery
} from 'src/services/applications'
import { useMemo } from 'react'
import { useGetLoanCardQuery } from 'src/services/loans'

const useGetApplication = (id: string) => {
  const {
    data: singleApplication,
    error: listError,
    isLoading: listLoading
  } = useGetSingleApplicationQuery(id, {
    skip: !id
  })

  const { data: documents, isLoading: documentsLoading } =
    useGetApplicationDocumentsQuery(id, {
      skip: !id
    })
  const { data: documentsSigned, isLoading: signedDocumentsLoading } =
    useGetApplicationSignedDocumentsQuery(id, {
      skip: !id
    })

  const { data: loan, isLoading: loanLoading } = useGetApplicationLoanQuery(
    id,
    {
      skip: !id || singleApplication?.status !== 'Issued'
    }
  )
  const { data: loanCard } = useGetLoanCardQuery(loan?.id || '', {
    skip: !loan?.id
  })

  const someLoading =
    listLoading || documentsLoading || loanLoading || signedDocumentsLoading

  const application = useMemo((): undefined | Partial<CombineApplication> => {
    if (someLoading) return undefined
    return {
      ...singleApplication,
      documents: documents?.documents || [],
      documentsSigned: documentsSigned || [],
      loan: loan
        ? {
            ...loan,
            loan_issue_card: loanCard?.data || null
          }
        : null
    }
  }, [
    documents?.documents,
    documentsSigned,
    loan,
    loanCard?.data,
    singleApplication,
    someLoading
  ])

  return { application, listError, someLoading }
}

export default useGetApplication
