import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
  Button,
  FormFeedback
} from 'reactstrap'
import { useSearchParams } from 'react-router-dom'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import * as Yup from 'yup'
import { useUpdateSettingMutation } from 'src/services/redirectSettings'

export const RedirectSettingsEdit = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const [
    updateSetting,
    {
      isSuccess: isSuccessUpdateSetting,
      error: createErrorUpdateSetting,
      isLoading: isLoadingUpdateSetting
    }
  ] = useUpdateSettingMutation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: Number(id),
      interval_min: 0,
      interval_max: 0
    },
    validationSchema: Yup.object().shape({
      interval_min: Yup.number()
        .typeError(t('enter number'))
        .min(0, t('parameter with a limitation from 0 to 100'))
        .max(100, t('parameter with a limitation from 0 to 100'))
        .required(t('Field is required')),
      interval_max: Yup.number()
        .typeError(t('enter number'))
        .min(0, t('parameter with a limitation from 0 to 100'))
        .max(100, t('parameter with a limitation from 0 to 100'))
        .required(t('Field is required'))
    }),
    onSubmit: (values) => {
      values.interval_min = Number(values.interval_min)
      values.interval_max = Number(values.interval_max)

      updateSetting(values)
    }
  })

  const title = t('Edit')

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            createLink={undefined}
            backLink={'/redirect-settings'}
            editLink={undefined}
            entity={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col className='col-3'>
                <FormGroup className='mb-3'>
                  <Label htmlFor={'interval_min'}>{t('interval min')}</Label>
                  <Input
                    name={'interval_min'}
                    type='text'
                    id={'interval_min'}
                    className='form-control form-control-sm'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.interval_min || ''}
                    disabled={isLoadingUpdateSetting}
                  />
                </FormGroup>
                {formik.touched.interval_min && formik.errors.interval_min ? (
                  <FormFeedback
                    style={{ display: 'block', marginBottom: '10px' }}
                    type='invalid'
                  >
                    <Alert color='warning'>
                      <strong> {formik.errors.interval_min} </strong>
                    </Alert>
                  </FormFeedback>
                ) : null}
              </Col>

              <Col className='col-3'>
                <FormGroup className='mb-3'>
                  <Label htmlFor={'interval_max'}>{t('interval max')}</Label>
                  <Input
                    name={'interval_max'}
                    type='text'
                    id={'interval_max'}
                    className='form-control form-control-sm'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.interval_max || ''}
                    disabled={isLoadingUpdateSetting}
                  />
                </FormGroup>
                {formik.touched.interval_max && formik.errors.interval_max ? (
                  <FormFeedback
                    style={{ display: 'block', marginBottom: '10px' }}
                    type='invalid'
                  >
                    <Alert color='warning'>
                      <strong> {formik.errors.interval_max} </strong>
                    </Alert>
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>

            {createErrorUpdateSetting && (
              <Alert color='warning'>
                <strong> {JSON.stringify(createErrorUpdateSetting)} </strong>
              </Alert>
            )}

            {isSuccessUpdateSetting && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Button
              type='submit'
              color='primary'
              style={{ backgroundColor: '#405189' }}
              disabled={isLoadingUpdateSetting}
            >
              {t('Edit')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
